import request from './request';
import { objectToQueryParams, encodeURL } from 'utils/queryParams';
import isEmpty from 'lodash/isEmpty';

const staffApis = {
    staffs: '/staffs',
    staff: (userId) => `/staffs/${userId}`,
    workingHours: `/staff-schedules`,
    workingHoursByStaff: `/staff-schedules/all`,
    staffWorkings: `staffs/working-hours`,
    workingHour: (id) => `/staff-schedules/${id}`,
    upload: (staffId) => `/staffs/${staffId}/avatar/upload`,
    closedDates: '/closed-dates',
    closedDate: (id) => `/closed-dates/${id}`,
    collision: `/staff-schedules/collision`,
    reorder: `/staffs/reorder`,
    reorderTurn: `/staffs/reorder-turn`,
    updatePassword: (staffId) => `/staffs/${staffId}/update-password`,
    setPassword: (staffId) => `/staffs/${staffId}/set-password`,
    activateStaff: (staffId) => `/staffs/${staffId}/activate`,
    inTurnStaffs: `/staffs/in-turn-staffs`,
    outTurnStaffs: `/staffs/out-turn-staffs`,
    addToTurn: (staffId) => `/staffs/${staffId}/add-to-turn`,
    removeOfOutTurn: (staffId) => `/staffs/${staffId}/remove-out-of-turn`,
    resetStaffTurn: `/staffs/reset-turns`,
    import: (merchantId) => `/staffs/import/${merchantId}`,
    serviceStaff: (id) => `/service-staff/${id}`,
    publicStaff: (slug) => `/staffs/public/${slug}`
};

export async function getInTurnStaffsApi(query) {
    let extraUrl = staffApis.inTurnStaffs;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function getOutTurnStaffsApi(query) {
    let extraUrl = staffApis.outTurnStaffs;
    if (!isEmpty(query)) {
        extraUrl += `?${objectToQueryParams(query)}`;
    }
    const response = await request.get(extraUrl);
    return response.data;
}

export async function addStaffToTurnApi(staffId, body) {
    const response = await request.put(`${staffApis.addToTurn(staffId)}`, body);
    return response.data;
}

export async function removeStaffOutOfTurnApi(staffId) {
    const response = await request.put(`${staffApis.removeOfOutTurn(staffId)}`);
    return response.data;
}

export async function resetStaffTurnApi() {
    const response = await request.post(`${staffApis.resetStaffTurn}`);
    return response.data;
}

export async function reOrderStaffApi(body) {
    const response = await request.post(`${staffApis.reorder}`, body);
    return response.data;
}

export async function reOrderStaffTurnApi(body) {
    const response = await request.post(`${staffApis.reorderTurn}`, body);
    return response.data;
}

export async function uploadAvatarApi({ file, staffId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.put(staffApis.upload(staffId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function createStaffWorkingHoursApi({ body, updateUpComing = false }) {
    let url = `${staffApis.workingHours}`;

    if (typeof updateUpComing === 'boolean') {
        url += `?updateUpComing=${updateUpComing}`;
    }
    const response = await request.post(url, body);
    return response.data;
}

export async function updateStaffWorkingHoursApi({ id, body, updateUpComing = false }) {
    const response = await request.put(`${staffApis.workingHour(id)}?updateUpComing=${updateUpComing}`, body);
    return response.data;
}

export async function deleteStaffWorkingHoursApi({ scheduleId, dateStart, updateUpComing = false }) {
    let url = `${staffApis.workingHour(scheduleId)}?dateStart=${dateStart}T00:00:00Z`;

    if (typeof updateUpComing === 'boolean') {
        url += `&updateUpComing=${updateUpComing}`;
    }
    const response = await request.delete(url);
    return response.data;
}

export async function getStaffWorkingHoursApi({ dateStart, dateEnd }) {
    const response = await request.get(`${staffApis.workingHoursByStaff}?dateStart=${dateStart}&dateEnd=${dateEnd}`);
    return response.data;
}

export async function getStaffCalendarWorkingHoursApi({ startDate }) {
    const response = await request.get(`${staffApis.staffWorkings}?startDate=${startDate}`);
    return response.data;
}

export async function createStaffApi({ body }) {
    const response = await request.post(`${staffApis.staffs}`, body);
    return response.data;
}

export async function getStaffApi({ query }) {
    let extraUrl = `${staffApis.staffs}?relations=staffServices`;
    if (!isEmpty(query)) {
        extraUrl += `&query=${encodeURL(query)}`;
    }

    const response = await request.get(`${extraUrl}`);
    return response.data;
}

export async function updateStaffApi({ id, body }) {
    const response = await request.put(`${staffApis.staff(id)}`, body);
    return response.data;
}

export async function deleteStaffApi({ id }) {
    const response = await request.delete(staffApis.staff(id));
    return response.data;
}

export async function getClosedDatesApi() {
    const response = await request.get(staffApis.closedDates);
    return response.data;
}

export async function createClosedDateApi({ body }) {
    const response = await request.post(staffApis.closedDates, body);
    return response.data;
}

export async function deleteClosedDateApi({ id }) {
    const response = await request.delete(staffApis.closedDate(id));
    return response.data;
}

export async function updateClosedDateApi({ id, body }) {
    const response = await request.put(staffApis.closedDate(id), body);
    return response.data;
}

export async function checkCollisionsApi(query) {
    const params = objectToQueryParams(query);
    const response = await request.get(`${staffApis.collision}?${params}`);
    return response.data;
}

export async function changeStaffPasswordApi({ staffId, body }) {
    const response = await request.put(`${staffApis.updatePassword(staffId)}`, body);
    return response.data;
}

export async function setStaffPasswordApi({ staffId, body }) {
    const response = await request.put(`${staffApis.setPassword(staffId)}`, body);
    return response.data;
}

export async function activateStaffApi({ id }) {
    const response = await request.put(staffApis.activateStaff(id));
    return response.data;
}

export async function importStaff({ file, merchantId }) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await request.post(staffApis.import(merchantId), formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export async function updateServiceStaffApi({ id, body }) {
    const response = await request.put(staffApis.serviceStaff(id), body);
    return response.data;
}

export async function getPublicStaffsApi(slug) {
    const response = await request.get(`${staffApis.publicStaff(slug)}`);
    return response.data;
}
