import { COLORS } from 'utils/color';
import { TIMEZONE } from 'utils/timing';
export const TOKEN_NAME = '_tk001sds';
export const TOKEN_USER = '_tk001uds';
export const ZOOM_SETTING = '_z001dsc';
export const THEME_NAME = '_t001dsv';
export const FONT_SIZE = '_t001fst';
export const FILTER_STAFF = '_f001vl';
export const CHECKOUT_GIFT_TAX = '_f001cogt';
export const SECURE_PHONE_NAME = '_sp012n';

export const HEADER_HEIGHT = 0;
export const SUB_HEADER_HEIGHT = 60;
export const HEADER_TAB_HEIGHT = 48;
export const FOOTER_HEIGHT = 56;
export const DEFAULT_CURRENCY = '$';
export const AVATAR_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
export const IMPORT_FILE_EXTENSIONS = ['.csv'];
export const POLICY_FILE_EXTENSIONS = ['.pdf'];
export const DEFAULT_DURATION = 15;
export const SIDEBAR_LEFT_CONTENT = 150;
export const SIDEBAR_LEFT_WIDTH = 84;

export const PAGINATION_LIMIT_DEFAULT = 10;
export const PAGINATION_OPTIONS_PER_PAGE = [10, 50, 100];
export const PAGINATION_PAGE_DEFAULT = 1;
export const DEFAULT_VOUCHER_MESSAGE = `Wishing you peace, joy and happiness today and always!`;

export const INDEX_COLUMN_WIDTH = 48;
export const TABLE_HEADER_HEIGHT = 60;
export const MAX_ARRIVED_LIST_SHOW = 10;
export const TIME_LIFE_FIND_BOOKING = 5000;
export const DEFAULT_MINUTES = 15;
export const ROW_HEIGHT = 36;

export const SORTING = {
    DESCENDING: -1,
    ASCENDING: 1
};

export const BOOKING_STATES = {
    NEW: 0,
    CLIENT_CANCEL: 1,
    SEND_OTP: 2,
    DECLINE: 3,
    NO_SHOW: 4,
    CLIENT_CONFIRMED: 5,
    PENDING_PAYMENT: 6,
    CLIENT_ARRIVED: 15,
    WAIT: 25,
    SERVING: 35,
    END: 55
};

export const BOOKING_SERVICE_STATES = {
    WAITING: 0,
    CONFIRMED: 1,
    CANCELED: 2,
    NO_SHOW: 3,
    COMPLETED: 4,
    ARRIVED: 5,
    SERVING: 6,
    DONE: 7,
    PICKED: 8,
    WAIT_FOR_CHECK_IN: 9,
    DECLINE: 10
};

export const BOOKING_ACTIONS = {
    CLIENT_CONFIRMED: 'ClientConfirmed',
    SEND_OTP: 'SendOtp',
    CLIENT_CANCEL: 'ClientCancel',
    DECLINE: 'Decline',
    NO_SHOW: 'NoShow',
    CLIENT_ARRIVED: 'ClientArrived',
    WAIT: 'Wait',
    SERVING: 'Serving',
    END: 'End'
};

export const BookingTypes = {
    BLOCKED_TIME: 'BLOCKED_TIME',
    BOOKING: 'BOOKING'
};

export const STAFF_WORKING_HOURS_REPEAT_TYPES = {
    NO_REPEAT: 'norepeat',
    WEEKLY: 'weekly',
    ON_GOING: 'ongoing',
    SPECIFIC: 'specific'
};

export const CONFIRM_TYPES = {
    ERROR: `ERROR`,
    INFO: `INFO`
};

export const SMS_SEND_TYPE = {
    NOW: 0,
    INTEND: 1
};

export const SMS_SCHEDULE_SEND_TYPE = {
    NOW: 0,
    INTEND: 1
};

export const SMS_SCHEDULE_STATUS = {
    WAITING: 0,
    IN_PROGRESS: 1,
    SENT: 2,
    STUCK: 3
};

export const SMS_TOPIC_STATUS = {
    WAITING: 0,
    IN_PROGRESS: 1,
    SENT: 2,
    STUCK: 3
};

export const BOOKING_TYPES = {
    MANUAL: 0,
    ONLINE: 1,
    NO_REFERENCE: 2,
    INTERNAL: 3
};

export const REDEEM_TYPES = {
    OFFLINE: 0,
    ONLINE: 1,
    TOP_UP: 2
};

export const SENT_DATE_OPTIONS = [
    {
        label: 'Now',
        value: SMS_SEND_TYPE.NOW
    },
    {
        label: 'Intent',
        value: SMS_SEND_TYPE.INTEND
    }
];

export const GIFT_CODE_PAYMENT_TYPES = {
    0: 'Offline',
    1: 'Stripe',
    2: 'Top Up'
};

export const GIFT_CODE_REDEEM_TYPES = {
    0: 'Redeem',
    2: 'Top Up'
};

export const BOOKING_SERVICE_STATE = {
    0: 'New',
    1: 'Confirmed',
    2: 'Canceled',
    3: 'No show',
    4: 'Completed',
    5: 'Arrived',
    6: 'Serving',
    7: 'Completed',
    8: 'UpComing',
    9: `Wait for check-in`,
    10: `Decline`
};

export const getBookingStatusColor = {
    0: COLORS.blue,
    1: COLORS.darkGreen,
    2: COLORS.lycan,
    3: COLORS.red,
    4: COLORS.green,
    5: COLORS.green,
    6: COLORS.pink,
    7: `#5DCDE3`,
    8: COLORS.sun,
    9: COLORS.pink,
    10: COLORS.lycan
};

export const MERCHANT_PERMISSIONS = {
    GIFT: 'GIFT',
    INTERNAL: 'INTERNAL',
    INTERNAL_BASIC: 'INTERNAL_BASIC',
    INTERNAL_MEDIUM: 'INTERNAL_MEDIUM',
    NO_PREFERENCE: 'NO_PREFERENCE',
    SMS_REMINDER: 'SMS_REMINDER',
    REVIEW: 'REVIEW',
    SEND_EMAIL_WHEN_CREATE_BOOKING_MANUAL: `SEND_EMAIL_WHEN_CREATE_BOOKING_MANUAL`,
    SEND_SMS_WHEN_CREATE_BOOKING_MANUAL: 'SEND_SMS_WHEN_CREATE_BOOKING_MANUAL',
    CHECK_OUT: 'CHECK_OUT',
    FULL_SMS_ONLINE_BOOKING: 'FULL_SMS_ONLINE_BOOKING',
    SERVICE_COMMISSION: 'SERVICE_COMMISSION',
    TOP_UP_GIFT: 'TOP_UP_GIFT',
    CHECKOUT_LEGIT: 'CHECKOUT_LEGIT',
    STAFF_SERVICE_DURATION: 'STAFF_SERVICE_DURATION',
    CHECK_IN: 'CHECK_IN',
    FORCE_LOGOUT_AFTER_CHANGE_PASSWORD: 'FORCE_LOGOUT_AFTER_CHANGE_PASSWORD',
    HIDE_DURATION_ONLINE_BOOKING: 'HIDE_DURATION_ONLINE_BOOKING',
    DEPOSIT: 'DEPOSIT',
    DEPOSIT_AMOUNT: 'DEPOSIT_AMOUNT',
    FULL_SMS_REMINDER: 'FULL_SMS_REMINDER',
    DISABLED_DRAG_DROP_ON_TOUCH_DEVICE: 'DISABLED_DRAG_DROP_ON_TOUCH_DEVICE',
    QUICK_CHECK_IN: 'QUICK_CHECK_IN',
    ALLOW_SEND_CONFIRMATION_MANUAL_BOOKING: 'ALLOW_SEND_CONFIRMATION_MANUAL_BOOKING',
    SEND_BOOKING_OTP: 'SEND_BOOKING_OTP',
    SECURE_PHONE_NUMBER: 'SECURE_PHONE_NUMBER',
    UPDATE_BOOKING_CONFIRMED: 'UPDATE_BOOKING_CONFIRMED',
    RESIZE_MOVE_CONFIRMED: 'RESIZE_MOVE_CONFIRMED',
    EXPORT_CLIENTS: 'EXPORT_CLIENTS'
};

export const GROUP_MERCHANT_PERMISSIONS = {
    REDEEM_GIFTS: `REDEEM_GIFTS`
};

export const MerchantRealtimeActionTypes = {
    Logout: 'Logout'
};

export const UserState = {
    Active: 0,
    Disable: 1
};

export const ROOMS = {
    JOIN_ROOM: 'joinRoom',
    LEAVE_ROOM: 'leaveRoom',
    MODIFIED: 'modified',
    JOINED_ROOM: 'joinedRoom',
    LEAVED_ROOM: 'leavedRoom'
};

export const REALTIME_OBJECT_TYPES = {
    Booking: 'Booking',
    BookingService: 'BookingService'
};

export const REALTIME_ACTIONS = {
    UPDATE: 'UPDATE',
    CREATE: 'CREATE',
    DELETE: 'DELETE'
};
export const TOPICS = {
    bookingService: (merchantId) => `booking-services/${merchantId}`,
    booking: (merchantId) => `bookings/${merchantId}`,
    turn: (merchantId) => `turns/${merchantId}`,
    merchant: (merchantId) => `merchants/${merchantId}`
};

export const BOOKING_REALTIME_ACTIONS = {
    UPDATE: 'Update',
    CREATE: 'Create',
    DELETE: 'Delete',
    RESCHEDULE: 'Reschedule',

    NEW: 'New',
    SEND_OTP: 'SendOtp',
    CLIENT_CANCEL: 'ClientCancel',
    NO_SHOW: 'NoShow',
    CLIENT_ARRIVED: 'ClientArrived',
    WAIT: 'Wait',
    SERVING: 'Serving',
    DECLINE: 'Decline',
    END: 'End',
    CHECKOUT: 'Checkout'
};

export const TURN_REALTIME_ACTIONS = {
    UPDATE: 'Update',
    ADD: 'Add',
    REMOVE: 'Remove',
    RESET: 'Reset',
    RE_ORDER: 'ReOrder'
};

export const MERCHANT_DEFAULT_OPTIONS = {
    timezone: TIMEZONE,
    permissions: [{ value: MERCHANT_PERMISSIONS.NO_PREFERENCE, label: MERCHANT_PERMISSIONS.NO_PREFERENCE }],
    bookingCancelBeforeMinutes: 60,
    bookingBeforeMinutes: 60,
    checkInDistance: 1,
    applicationFeeAmount: 0,
    slotInterval: 15
};

export const MerchantState = {
    Active: 0,
    PendingPayment: 1,
    Deactivate: 2
};

export const SCHEDULE_TYPES = {
    CLONE: 'CLONE',
    MOVING: 'MOVING'
};
export const LIMIT_BOOKING_HISTORY = 20;

export const BOOKING_CHECKOUT_STATES = {
    NOT_PAY: 0,
    PENDING: 1,
    DONE: 2
};

export const PAYMENT_METHOD_TYPES = {
    CASH: 0,
    MASTERCARD: 1,
    DEBIT: 2,
    VISA: 3,
    GIFT: 4,
    GIFT_TAX: 5
};

export const PAYMENT_TYPE_BY_LABEL = {
    masterCard: PAYMENT_METHOD_TYPES.MASTERCARD,
    visa: PAYMENT_METHOD_TYPES.VISA,
    debit: PAYMENT_METHOD_TYPES.DEBIT,
    gift: PAYMENT_METHOD_TYPES.GIFT,
    cash: PAYMENT_METHOD_TYPES.CASH
};

export const CHECKOUT_TYPES = {
    BOOKING: 0,
    GIFT: 1
};

export const USER_TYPES = {
    Merchant: 0,
    Staff: 1,
    Client: 2,
    Receptionist: 3
};

export const BOOKING_SERVICE_STATUS_TO_LABEL = {
    0: 'New',
    1: 'Confirmed',
    2: 'Canceled',
    3: 'No show',
    4: 'Completed',
    5: 'Arrived',
    6: 'Serving',
    7: 'Completed',
    8: 'UpComing'
};

export const getBookingServiceStatusColor = {
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.WAITING]]: `#F8AD13`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.CONFIRMED]]: `#5DCDE3`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.CANCELED]]: `#FFCA41`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.NO_SHOW]]: `#F27A7A`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.COMPLETED]]: `#48C567`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.ARRIVED]]: `#48C567`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.SERVING]]: `#7474DC`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.DONE]]: `#98A5B5`,
    [BOOKING_SERVICE_STATUS_TO_LABEL[BOOKING_SERVICE_STATES.PICKED]]: `#EB4F5E`
};

export const TIME_SLOT_TYPES = {
    NO_REFERENCE: 0,
    TIME_SLOT: 1
};

export const BOOKING_LOGS_STATE_NUMBER = {
    MERCHANT: 0,
    CLIENT: 1,
    STAFF: 2
};

export const BOOKING_LOGS_STATE_LABEL = {
    0: `Merchant`,
    1: `Client`,
    2: `Staff`
};

export const BOOKING_STATE_LABEL = {
    0: 'New',
    1: 'Canceled',
    2: 'Send Otp',
    3: 'Declined',
    4: 'No show',
    5: 'Confirmed',
    6: 'Pending payment',
    15: 'Arrived',
    25: 'Waiting',
    35: 'Serving',
    55: 'Done'
};

export const THEMES = {
    MANGO_MINT: 'MANGO_MINT',
    BOOK_SALON: 'BOOK_SALON'
};
